export default `<!DOCTYPE html>
<html>
  <head>
    <title>Hello world</title>
    {listener}
  </head>
  <body>
    <a
      href="{payload}"
      title="Click me"
    >
      Click me
    </a>
  </body>
</html>
`;
