export default `<!DOCTYPE html>
<html>
  <head>
    <title>Hello world</title>
    {listener}
  </head>
  <body>
    <img
      src="{payload}"
    />
  </body>
</html>
`;
